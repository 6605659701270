<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>

body{
  font-family: 'Montserrat', sans-serif;
  color: rgb(29, 29, 29);
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

</style>